<template>
    <van-row class="MyRangeInput">
        <van-col span="14">
            <van-field v-model="startValue"
                       :border="false"
                       :label="label"
                       :placeholder="startPlaceholder"
                       :type="type"
                       v-bind="$attrs"
                       @input="onInput"
            />
        </van-col>
        <van-col class="MyRangeInput__separator" span="2">—</van-col>
        <van-col span="8">
            <van-field v-model="endValue"
                       :border="false"
                       :colon="false"
                       :placeholder="endPlaceholder"
                       :type="type"
                       @input="onInput"
            />
        </van-col>
    </van-row>
</template>

<script>
    export default {
        name: "MyRangeInput",
        props: {
            value: {
                type: Array,
                required: true
            },
            label: String,
            type: String,
            startPlaceholder: String,
            endPlaceholder: String,
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                startValue: '',
                endValue: '',
            }
        },
        watch: {
            value: {
                handler() {
                    this.setInputValue();
                }
            }
        },
        mounted() {
            this.setInputValue();
        },
        methods: {
            setInputValue() {
                let [start = '', end = ''] = this.value;
                this.startValue = start;
                this.endValue = end;
            },
            onInput() {
                let start = Number(this.startValue);
                let end = Number(this.endValue);
                // if (start && end) {
                //     window.console.log('5445454');
                //     if (start > end) {
                //         window.console.log('11111');
                //         this.startValue = 0;
                //     } else if (end < start) {
                //         window.console.log('22222');
                //         this.endValue = 0;
                //     }
                // }
                // window.console.log([start, end]);
                this.$emit('input', [start, end]);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .MyRangeInput {
        &__separator {
            line-height: 44px;
            text-align: center;
        }
    }
</style>